// @ts-ignore
import * as videoReact from 'video-react';
import {styled} from "@mui/material";
import {useParams} from "react-router-dom";

const StyledPlayer = styled(videoReact.Player)(({theme}) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    margin: 'auto'

}));

function VideoPlayer() {
    let {height} = useParams<"height">();
    let {width} = useParams<"width">();
    let {shortname} = useParams<"shortname">();
    let {filename} = useParams<"filename">();
    const StyledDiv = styled('div')(({theme}) => ({
        display: "flex",
        height: height + "px",
        width: width + "px",
        backgroundColor: theme.palette.background.default,
    }));
    return (
        <StyledDiv>
            <StyledPlayer
                autoPlay={true}
                fluid={false}
                width={width}
                height={height}
            >
                <source
                    src={process.env.REACT_APP_VIDEO_BASE_URL + shortname + "/" + filename}
                    type="video/mp4"

                />
                <videoReact.ControlBar>
                    <videoReact.ReplayControl seconds={10} order={1.1}/>
                    <videoReact.ForwardControl seconds={30} order={1.2}/>
                    <videoReact.CurrentTimeDisplay order={4.1}/>
                    <videoReact.TimeDivider order={4.2}/>
                    <videoReact.PlaybackRateMenuButton rates={[2, 1, 0.75, 0.5, 0.25, 0.1]} order={7.1}/>
                </videoReact.ControlBar>
            </StyledPlayer>
        </StyledDiv>
    );
}

export default VideoPlayer;
