import React from 'react';
import './App.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import VideoCategory from "./pages/VideoCategory";
import Videos from "./pages/Videos";
import Home from "./pages/Home";
import {responsiveFontSizes, styled, ThemeProvider} from "@mui/material";
import VideoPlayer from "./pages/VideoPlayer";
import Syllabus from "./pages/Syllabus";
import Classes from "./pages/Classes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Logout from "./pages/Logout";
import NoPageFound from "./pages/NoPageFound";
import GojuRyuHistory from "./pages/GojuRyuHistory";
import theme from "./Theme";
import {Amplify} from "aws-amplify";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
    }
});

const StyledMain = styled('main')(
    ({theme}) => `
    background-color: ${theme.palette.background.paper};    
    flex: 1;
    overflow-y: auto;
    max-width: '1024px';
    height: '100%'    
  `,
);

const Content = styled('div')(
    ({theme}) => `
    // flex: 1;
    // overflow-y: auto;
    height: calc('100%');
  `,
);

const StyledApp = styled('div')(
    ({theme}) => `
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: 'whitesmoke';
  `,
);

const StyledFooter = styled('footer')(
    ({theme}) => `
    height: 28px;
    color: 'whitesmoke';
    text-align: center;
  `,
);

const menuOptions = [
    {title: 'Home', url: '/'},
    {title: 'Classes', url: '/classes'},
    {title: 'History', url: '/history'},
    {title: 'Syllabus', url: '/syllabus'},
    {title: 'Videos', url: '/videos'},
    {title: 'Logout', url: '/logout'}
];


function App() {
    let fontTheme = responsiveFontSizes(theme);
    return (
        <BrowserRouter>

            <ThemeProvider theme={fontTheme}>
                <StyledApp>
                    <Header title="Blackrod and Bolton Karate Club" sections={menuOptions}/>
                    <StyledMain>
                        <Content>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/classes" element={<Classes/>}/>
                                <Route path="/history" element={<GojuRyuHistory/>}/>
                                <Route path="/syllabus" element={<Syllabus/>}/>
                                <Route path="/videos" element={<Videos/>}/>
                                <Route path="/logout" element={<Logout/>}/>
                                <Route path="/videos/:shortname" element={<VideoCategory/>}/>
                                <Route path="/video-player/:shortname/:filename/:width/:height"
                                       element={<VideoPlayer/>}/>
                                <Route path="*" element={<NoPageFound/>}/>

                            </Routes>
                        </Content>
                    </StyledMain>
                    <StyledFooter>
                        <Footer/>
                    </StyledFooter>
                </StyledApp>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;

