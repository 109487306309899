import {styled, Typography} from "@mui/material";
import {I18n} from 'aws-amplify';
import {AmplifyProvider, Authenticator, Button, useAuthenticator, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import VideoContent from "../components/VideoContent";
import theme from "../Theme";


I18n.putVocabulariesForLanguage('en', {
    'Sign In': 'Login', // Tab header
    'Sign in': 'Log in', // Button label
    'Sign in to your account': 'Welcome Back!',
    Username: 'Enter your email address', // Username label
    Password: 'Enter your password', // Password label
    'Forgot your password?': 'Reset Password',
    'Family Name': 'Surname',
    'Given Name': 'Firstname'
});
const AuthTheme = {
    name: 'darkly',
    tokens: {
        colors: {
            background: {
                primary: theme.palette.background.default
            }
        }
    }
}
const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke",
    padding: theme.spacing(1)
}));
const StyledDiv = styled('div')(({theme}) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke",
    margin: 'auto'
}));
const SignUpDiv = styled('div')(({theme}) => ({
    color: 'black',
    marginTop: 'auto'
}));
const formFields = {
    signUp: {
        email: {
            order: 1,
            placeholder: 'Enter your email address',
        },
        given_name: {
            order: 2,
            placeholder: 'Enter your first name',
        },
        family_name: {
            order: 3,
            placeholder: 'Enter your surname'
        },
        password: {
            order: 4
        },
        confirm_password: {
            order: 5
        }
    },
}

const components = {

    SignUp: {
        Footer() {
            const {toSignIn} = useAuthenticator();

            return (
                <View textAlign="center">
                    <SignUpDiv>
                        We need to verify your identity after you've created your account, please ask in the WhatsApp
                        group.
                    </SignUpDiv>
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Back to Sign In
                    </Button>
                </View>
            );
        },
    },
};


function Videos() {


    return (
        // <StyledFullWidthDiv>
            <AmplifyProvider colorMode={'dark'}>
                <Authenticator formFields={formFields} components={components} variation={"modal"}
                               loginMechanisms={['email']}>
                    {({signOut, user}) => (
                        <div>
                            <StyledDiv>
                                <Typography variant='h6'>These videos are for your personal use, and should not
                                    be shared outside the members
                                    of the Bolton & Blackrod Academy of Goju Ryu Karate-do</Typography>
                            </StyledDiv>

                            <VideoContent/>
                        </div>
                    )}
                </Authenticator>
            </AmplifyProvider>
        // </StyledFullWidthDiv>

    );


}

export default Videos;
