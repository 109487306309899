import {Card, CardContent, CardHeader, CardMedia, Grid, styled} from "@mui/material";
import React from "react";


const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));

const StyledCard = styled(Card)(({theme}) => ({
    width: 320,
    height: 320,
    minWidth: 320,
    backgroundColor: theme.palette.background.paper,
}));
const FullWidthCard = styled(Card)(({theme}) => ({
    // width: '95%',
    // minWidth: '95%',
    backgroundColor: theme.palette.background.paper,
}));
const HalfWidthCard = styled(Card)(({theme}) => ({
    // width: '50%',
    // minWidth: '50%',
    backgroundColor: theme.palette.background.paper,
}));
const StyledGrid = styled(Grid)(({theme}) => ({
    justify: 'space-around',
    maxWidth: "lg"
}));
const StyledGridItem = styled(Grid)(({theme}) => ({
    minWidth: '350px'

}));
const FullWidthGridItem = styled(Grid)(({theme}) => ({
    minWidth: '95%'

}));
const HalfWidthGridItem = styled(Grid)(({theme}) => ({
    minWidth: '44%%'

}));

function Home() {
    return (
        <StyledFullWidthDiv>
            <StyledGrid container spacing={2}>
                <FullWidthGridItem item xs={12} sm={12} md={10} key='Welcome' sx={{mx: "auto"}}>
                    <FullWidthCard>
                        <CardMedia component="img"
                                   image={process.env.REACT_APP_CONTENT_BASE_URL + "Home_Group.png"}
                                   alt="group photo"
                        />

                    </FullWidthCard>
                    <FullWidthCard>
                        <CardContent>
                            Welcome to the website of the Blackrod and Bolton Academy of Goju Ryu Karate Do. The club is
                            led by Chief Instructor Amanda Winstanley (BKA 8th Dan(Hachidan)) with over 45 years of
                            experience. We practice traditional Goju Ryu Karate which is an effective martial art, with
                            a view to self-defence.
                        </CardContent>
                        <CardContent>
                            The club is over 35 years old and boasts over 40? active Blackbelts to 7th Dan grade. We
                            train in Blackrod and across Bolton, please see the <a
                            href='/classes'>classes</a> page for
                            details of class locations and timings.
                        </CardContent>


                    </FullWidthCard>

                </FullWidthGridItem>

                <FullWidthGridItem item xs={12} sm={8} md={8} key='2#' sx={{mx: "auto"}}>
                    <FullWidthCard>
                        <CardContent>
                            <li>We teach traditional Goju Ryu Karate with a modern view to self defence</li>
                            <li>Ages from 5+ are welcome</li>
                            <li>Beginners always welcome</li>
                            <li>Mixed ability classes allow progression at your own pace</li>
                            <li>The Club prides itself on a friendly and open atmosphere were everyone is willing to
                                help you achieve your goals.
                            </li>
                        </CardContent>

                    </FullWidthCard>

                </FullWidthGridItem>
                <HalfWidthGridItem item xs={12} sm={4} md={4} key='3#' sx={{mx: "auto"}}>
                    <FullWidthCard>
                        <CardHeader title='We teach our core values:'/>
                        <CardContent>
                            <li>Character</li>
                            <li>Sincerity</li>
                            <li>Effort</li>
                            <li>Etiquette</li>
                            <li>Self Control</li>
                            <li>Dedication</li>
                        </CardContent>

                    </FullWidthCard>
                </HalfWidthGridItem>
            </StyledGrid>

        </StyledFullWidthDiv>
    )
        ;
}

export default Home;
