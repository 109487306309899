import {useNavigate} from "react-router-dom";
import {videoCategories} from "../common/videoCategories";
import {Card, CardHeader, CardMedia, Grid, styled} from "@mui/material";

const StyledDiv = styled('div')(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    backgroundColor: theme.palette.background.paper,

}));

const StyledCard = styled(Card)(({theme}) => ({
    width: 250,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
    textAlign: 'center'
}));
const StyledGrid = styled(Grid)(({theme}) => ({

}));
const StyledGridItem = styled(Grid)(({theme}) => ({
    minWidth: '270px'
}));
const StyledCardMedia = styled(CardMedia)(({theme}) => ({
    height: 190, paddingTop: '0', width: 250,
    backgroundSize: 'contain'
}));

function VideoContent() {
    let navigate = useNavigate();
    // @ts-ignore
    return (
        <StyledDiv>
            <StyledGrid container spacing={1}>
                {videoCategories.map(tile => (
                    <StyledGridItem item xs={12} sm={6} md={4} lg={2} key={tile.id}
                                    onClick={() => navigate('/videos/' + tile.shortname)}>
                        <StyledCard>
                            <CardHeader title={tile.text}/>
                            <StyledCardMedia
                                image={process.env.REACT_APP_VIDEO_BASE_URL + tile.shortname + "/signature.png"}
                                title={tile.text}
                            />
                        </StyledCard>
                    </StyledGridItem>
                ))}
            </StyledGrid>
        </StyledDiv>
    );

}


export default VideoContent;
