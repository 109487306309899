import {createTheme} from "@mui/material";


const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#f111ff',
            main: '#cfd8dc',
            dark: '#9ea7aa',
            contrastText: '#000000',
        },
        secondary: {
            light: '#f111ff',
            main: '#0e0e0e',
            dark: '#aeaeae',
            contrastText: '#000',
        },
    },
});

export default theme;
